import React from 'react'
import { graphql, Link } from 'gatsby'
import { Row, Col } from 'react-bootstrap'
import Section from 'components/section'
import Helmet from 'react-helmet'
import Layout from 'components/layout'
import Metadata from '../../constants/metadata'
import { InnerContent, MainContainer } from 'components/container'
import { Theme } from '../../constants/variables'

class Index extends React.Component {
  render() {
    return (
      <div>
        <Helmet>
          <title>Thank You!</title>
          <meta name="title" content={Metadata.Contact.metaTitle} />
          <meta name="description" content={Metadata.Contact.metaDescription} />
        </Helmet>
        <Layout
          isHome={false}
          sections={Metadata.Navigation.Links}
        >
          <Section id="form-success" style={{ backgroundColor: Theme.color.dark }}>
            <MainContainer>
              <InnerContent>
                <Row>
                  <Col md={12} className="fadeIn">
                    <InnerContent>
                      <h2>Thank You! Your form was submitted successfully.</h2>
                      <p>Someone from Blueprint will be in touch very soon.</p>
                      <Link to="/">Great, now take me home!</Link>
                    </InnerContent>

                  </Col>
                </Row>
              </InnerContent>
            </MainContainer>
          </Section>
        </Layout>
      </div>
    )
  }
}

export default Index

export const query = graphql`
        query {
          site {
          meta: siteMetadata {
          title
        description
      }
    }
  }
        `